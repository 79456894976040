import React from 'react';
import { makeStyles } from '@mui/styles';
import { Section, SectionAlternate } from 'components/organisms';

import {
  Browsers,
  DownloadLinks,
  Features,
  HeroSection,
  HowLokeyWorks,
  Integrations,
  Partners,
  Solutions,
  Subscription,
  UseCases,
  VideoSection,
  Webinars,
  WhatIsLokey,
} from './components';

import {
  useCases,
  partners,
  features,
  browsers,
  advantages,
  webinars,
  platforms,
} from './data';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },
}));

const LokeyHome = () => {
  const classes = useStyles();

  return (
    <div>
      <Helmet><title>Lokey</title></Helmet>
      {/* <Section className={classes.pagePaddingTop}> */}
        <HeroSection />
      {/* </Section> */}
      <Section className={classes.pagePaddingTop}>
        <WhatIsLokey />
      </Section>
      <SectionAlternate className={classes.pagePaddingTop}>
        <HowLokeyWorks />
      </SectionAlternate>

      <Section className={classes.pagePaddingTop}>
      <UseCases data={useCases} />
      </Section>
      <SectionAlternate className={classes.pagePaddingTop}>
        <DownloadLinks />
      </SectionAlternate>
      {/* <SectionAlternate className={classes.pagePaddingTop}>
        <VideoSection />
      </SectionAlternate> */}
  {/*    <SectionAlternate>
        <Solutions data={features} />
      </SectionAlternate>
      <Section>
        <>
        <Browsers data={browsers} />
        <Section>
          <Divider />
        </Section>
        <Features data={advantages} />
        </>
      </Section>
      <SectionAlternate innerNarrowed>
        <Webinars data={webinars} />
      </SectionAlternate>
      <Section>
        <Integrations data={platforms} />
      </Section> */}
      {/* <SectionAlternate innerNarrowed className={classes.sectionAlternate}>
        <Subscription />
      </SectionAlternate>     */}
    </div>
  );
};

export default LokeyHome;
