import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import WithLayout from './WithLayout'; // Ensure correct path
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  Home as HomeView,
  LokeyHome as LokeyHome,
  About as AboutView,
  NotFound as NotFoundView,
  NotFoundCover as NotFoundCoverView,
  Policies as PrivacyPolicyView,
  ProfileDelete as ProfileDeleteView,  
} from './views';
import RedirectComponent from 'components/atoms/Redirect';

const RoutesConfig = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <WithLayout
            component={LokeyHome}
            layout={MainLayout}
          />
        }
      />
      <Route
        path="/policy/privacy"
        element={
          <WithLayout
            component={PrivacyPolicyView}
            layout={MainLayout}
          />
        }
      />
      <Route
        path="/profile/delete"
        element={
          <WithLayout
            component={ProfileDeleteView}
            layout={MainLayout}
          />
        }
      />
      <Route
        path="/about"
        element={
          <WithLayout
            component={AboutView}
            layout={MainLayout}
          />
        }
      />
     {/* <Route
        path="/home"
        element={
          <WithLayout
            component={HomeView}
            layout={MainLayout}
          />
        }
      />
       <Route
        path="/solution-offering"
        element={
          <WithLayout
            component={SolutionOfferingView}
            layout={MainLayout}
          />
        }
      />
       */}
      <Route path="/:appname/:encoded?" element={<RedirectComponent />} />
      <Route
        path="/not-found"
        element={
          <WithLayout
            component={NotFoundView}
            layout={MinimalLayout}
          />
        }
      />
      <Route
        path="/not-found-cover"
        element={
          <WithLayout
            component={NotFoundCoverView}
            layout={MinimalLayout}
          />
        }
      />
      <Route path="*" element={<Navigate to="/not-found-cover" replace />} />
    </Routes>
  );
};

export default RoutesConfig;
