import { useEffect } from 'react';
import { useLocation , useParams } from 'react-router-dom';

const RedirectComponent = () => {
  const location = useLocation();
  const { appname } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const appMarketParam = searchParams.get('appmarket');

    if (appMarketParam === 'true') {
      const appUrl ='https://play.google.com/store/apps/details?id=com.mindsclik.' + appname;
      window.location.replace(appUrl);
    }
  }, [location.search, appname]);

  return null; // This component doesn't render anything, as the redirection happens in the useEffect hook
};

export default RedirectComponent;
