import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  useMediaQuery,
  Typography,
  Box,
  Grid,
  Button,
  Grid2,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@mui/material';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { Image } from 'components/atoms';
import { Apple, Android } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  cardBase: {
    boxShadow: 'none',
    background: theme.palette.alternate.main,
    borderRadius: theme.spacing(1),
    '& .card-base__content': {
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
    },
  },
  avatar: {
    width: 110,
    height: 110,
    border: `4px solid ${theme.palette.alternate.dark}`,
    borderRadius: '100%',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  },
  listItem: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 0,
    height: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
}));

const HowLokeyWorks = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Download Lokey Apps"
        subtitle="To provide you with the best user experience, our platform is exclusively available on our mobile app, supporting both Android and iOS devices."
      />
      <Grid2 data-aos={'fade-up'} container spacing={3} justifyContent="center">

          {/* <Grid container spacing={3} justifyContent="center"> */}
            {/* Lokey User App */}
            <Grid2 size={{xs:12, sm:6}}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  p: 3,
                  textAlign: "center",
                  backgroundColor: "#ffffff",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Lokey User App
                </Typography>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Android />}
                    href="https://play.google.com/store/apps/details?id=com.mindsclik.lokey"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      borderRadius: "50px",
                      fontSize: "0.875rem",
                    }}
                  >
                    Google Play
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Apple />}
                    href="https://apps.apple.com/in/app/lokey-app/id6702021952"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      borderRadius: "50px",
                      fontSize: "0.875rem",
                    }}
                  >
                    App Store
                  </Button>
                </Box>
              </Box>
            </Grid2>

            {/* Lokey Vendor App */}
            <Grid2 size={{xs:12, sm:6}}>
              <Box
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  p: 3,
                  textAlign: "center",
                  backgroundColor: "#ffffff",
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Lokey Vendor App
                </Typography>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Android />}
                    href="https://play.google.com/store/apps/details?id=com.mindsclik.lokeyv"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      borderRadius: "50px",
                      fontSize: "0.875rem",
                    }}
                  >
                    Google Play
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Apple />}
                    href="https://apps.apple.com/in/app/lokey-vendor/id6736947809"
                    target="_blank"
                    sx={{
                      textTransform: "none",
                      borderRadius: "50px",
                      fontSize: "0.875rem",
                    }}
                  >
                    App Store
                  </Button>
                </Box>
              </Box>
            </Grid2>
          {/* </Grid>         */}
      </Grid2>
    </div>
  );
};

HowLokeyWorks.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  // data: PropTypes.array.isRequired,
};

export default HowLokeyWorks;
